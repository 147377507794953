.dashboard-container {
  padding-top: 90px;
  background: #f8fafc;
  min-height: 100vh;
}

.dashboard-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.dashboard-header {
  background: white;
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.08);
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-header h1 {
  font-size: 2.5rem;
  color: #2c1810;
  margin: 0;
}

.logout-button {
  padding: 0.8rem 1.5rem;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #c82333;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.dashboard-item {
  background: white;
  padding: 2.5rem;
  border-radius: 1rem;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.08);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.dashboard-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.12);
}

.dashboard-item h3 {
  font-size: 1.8rem;
  color: #2c1810;
  margin-bottom: 1rem;
}

.dashboard-item p {
  color: #5a4a42;
  font-size: 1.1rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .dashboard-content {
    padding: 2rem 1.5rem;
  }

  .dashboard-header {
    padding: 2rem;
    flex-direction: column;
    gap: 1.5rem;
    text-align: center;
  }

  .dashboard-header h1 {
    font-size: 2rem;
  }

  .dashboard-grid {
    grid-template-columns: 1fr;
  }

  .dashboard-item {
    padding: 2rem;
  }
}
