* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  overflow-x: hidden;
}

.App {
  min-height: 100vh;
}

main {
  padding-top: 80px; /* Giver plads til den faste header */
}

/* Generelle styling klasser */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Typografi */
h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
  line-height: 1.2;
}

p {
  margin-bottom: 1rem;
}

/* Farver - du kan tilpasse disse efter Louise Munks brand */
:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --accent-color: #e74c3c;
  --text-color: #333333;
  --light-gray: #f8fafc;
  --white: #ffffff;
}

/* Smooth scroll behavior */
html {
  scroll-behavior: smooth;
}

/* Fjern outline på fokuserede elementer */
:focus {
  outline: none;
}

/* Tilføj custom outline style */
:focus-visible {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Skjul scrollbar men behold funktionalitet */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--light-gray);
}

::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

/* Responsive design */
@media (max-width: 768px) {
  main {
    padding-top: 70px;
  }
}
