.login-container {
  padding-top: 90px; /* Matcher BehandlingSide header offset */
  background: #f8fafc;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box {
  background: white;
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.08);
  width: 100%;
  max-width: 450px;
  margin: 2rem;
}

.login-box h2 {
  font-size: 2.2rem;
  color: #2c1810;
  text-align: center;
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group input {
  width: 100%;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: #3d6351;
}

.login-button {
  width: 100%;
  padding: 1rem;
  background-color: #3d6351;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #325343;
}

.error-message {
  background: #fee2e2;
  color: #dc2626;
  padding: 1rem;
  border-radius: 0.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.forgot-password-button {
  background: none;
  border: none;
  color: #3d6351;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 1rem;
  text-decoration: underline;
  width: 100%;
}

.forgot-password-button:hover {
  color: #325343;
}

.success-message {
  background: #dcfce7;
  color: #166534;
  padding: 1rem;
  border-radius: 0.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .login-box {
    padding: 2rem;
    margin: 1.5rem;
  }

  .login-box h2 {
    font-size: 1.8rem;
  }
}
