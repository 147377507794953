.inspiration {
  position: relative;
  padding: 10rem 0;
  background: linear-gradient(
    180deg,
    #fff 0%,
    rgba(255, 255, 255, 0.95) 40%,
    #f8fafc 100%
  );
  overflow: hidden;
}

.inspiration-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

.inspiration-header {
  text-align: center;
  margin-bottom: 6rem;
}

.inspiration-subtitle {
  display: inline-flex;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #3d6351;
  background: rgba(61, 99, 81, 0.08);
  padding: 0.8rem 2rem;
  border-radius: 100px;
  margin-bottom: 2rem;
  font-weight: 600;
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.08);
}

.inspiration-header h2 {
  font-size: 3.8rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  color: #2c1810;
  font-weight: 800;
  letter-spacing: -1px;
}

.inspiration-header p {
  font-size: 1.2rem;
  line-height: 1.7;
  color: #5a4a42;
  max-width: 650px;
  margin: 0 auto;
  opacity: 0.9;
}

.inspiration-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
}

.inspiration-item {
  background: #f8fafc;
  padding: 3rem;
  border-radius: 2rem;
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none;
}

.inspiration-item:focus-visible {
  box-shadow: 0 0 0 3px #3d6351;
}

.inspiration-icon {
  width: 60px;
  height: 60px;
  background: white;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3d6351;
  font-size: 1.6rem;
  flex-shrink: 0;
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.1);
  transition: all 0.3s ease;
}

.inspiration-content {
  flex: 1;
}

.inspiration-content h3 {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #2c1810;
}

.inspiration-content p {
  font-size: 1rem;
  line-height: 1.6;
  color: #5a4a42;
  margin-bottom: 1.5rem;
  opacity: 0.9;
}

.inspiration-link {
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: #3d6351;
  transition: all 0.3s ease;
}

.arrow {
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.inspiration-item:hover .arrow {
  transform: translateX(5px);
}

.inspiration-item:hover {
  transform: translateY(-5px);
  background: white;
  box-shadow: 0 20px 40px rgba(61, 99, 81, 0.08);
}

.inspiration-item:hover .inspiration-icon {
  background: rgba(61, 99, 81, 0.12);
  color: #3d6351;
  transform: scale(1.1);
}

.inspiration-link:hover {
  color: #325343;
}

@media (max-width: 992px) {
  .inspiration {
    padding: 8rem 0;
  }

  .inspiration-header h2 {
    font-size: 3.2rem;
  }

  .inspiration-grid {
    grid-template-columns: 1fr;
    max-width: 650px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .inspiration {
    padding: 6rem 0;
  }

  .inspiration-header h2 {
    font-size: 2.8rem;
  }

  .inspiration-item {
    padding: 2rem;
    flex-direction: column;
    gap: 1.5rem;
    text-align: center;
  }

  .inspiration-icon {
    margin: 0 auto;
  }

  .inspiration-content h3 {
    font-size: 1.4rem;
  }

  .inspiration-link {
    justify-content: center;
  }
}
