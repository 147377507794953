.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}

.mobile-menu.open {
  opacity: 1;
  visibility: visible;
}

.mobile-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.mobile-nav li {
  margin: 2rem 0;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
}

.mobile-menu.open .mobile-nav li {
  opacity: 1;
  transform: translateY(0);
}

.mobile-nav a {
  color: #3d6351;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.mobile-nav a:hover {
  color: #325343;
}

.mobile-cta-button {
  display: inline-block;
  padding: 1rem 2.5rem;
  background: #3d6351;
  color: white !important;
  border-radius: 100px;
  margin-top: 1rem;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.15);
}

.mobile-cta-button:hover {
  background: #325343;
  transform: translateY(-2px);
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.2);
}

/* Animation delay for list items */
.mobile-nav li:nth-child(1) { transition-delay: 0.1s; }
.mobile-nav li:nth-child(2) { transition-delay: 0.2s; }
.mobile-nav li:nth-child(3) { transition-delay: 0.3s; }
.mobile-nav li:nth-child(4) { transition-delay: 0.4s; }
.mobile-nav li:nth-child(5) { transition-delay: 0.5s; }
.mobile-nav li:nth-child(6) { transition-delay: 0.6s; }
