.behandling {
  position: relative;
  padding: 10rem 0;
  background: linear-gradient(
    180deg,
    #f8fafc 0%,
    rgba(248, 250, 252, 0.95) 40%,
    #fff 100%
  );
  overflow: hidden;
}

.behandling-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

.behandling-header {
  text-align: center;
  margin-bottom: 6rem;
}

.behandling-subtitle {
  display: inline-flex;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #3d6351;
  background: white;
  padding: 0.8rem 2rem;
  border-radius: 100px;
  margin-bottom: 2rem;
  font-weight: 600;
  box-shadow: 0 15px 30px rgba(124, 85, 147, 0.08);
}

.behandling-header h2 {
  font-size: 3.8rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  color: #2c1810;
  font-weight: 800;
  letter-spacing: -1px;
}

.behandling-header p {
  font-size: 1.2rem;
  line-height: 1.7;
  color: #5a4a42;
  max-width: 650px;
  margin: 0 auto;
  opacity: 0.9;
}

.behandling-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
}

.behandling-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2rem;
  height: 100%;
}

.info-item {
  background: white;
  padding: 2.5rem;
  border-radius: 1.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.info-icon {
  width: 52px;
  height: 52px;
  background: rgba(61, 99, 81, 0.08);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3d6351;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.info-item h3 {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #2c1810;
}

.info-item p {
  font-size: 1rem;
  line-height: 1.6;
  color: #5a4a42;
  margin: 0;
  opacity: 0.9;
}

.info-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(124, 85, 147, 0.08);
}

.info-item:hover .info-icon {
  background: rgba(61, 99, 81, 0.12);
  transform: scale(1.1);
}

.process-card {
  background: white;
  padding: 3rem;
  border-radius: 2rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.process-card h3 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 2.5rem;
  color: #2c1810;
}

.process-steps {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-grow: 1;
  justify-content: space-between;
}

.step {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 3rem;
  position: relative;
}

.step-number {
  position: absolute;
  left: 0;
  font-size: 1.2rem;
  font-weight: 700;
  color: #3d6351;
  opacity: 0.8;
}

.step h4 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2c1810;
}

.step p {
  font-size: 1rem;
  line-height: 1.6;
  color: #5a4a42;
  opacity: 0.9;
}

@media (max-width: 1200px) {
  .behandling-content {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}

@media (max-width: 768px) {
  .behandling {
    padding: 6rem 0;
  }

  .behandling-header h2 {
    font-size: 2.8rem;
  }

  .behandling-info {
    grid-template-columns: 1fr;
  }

  .process-card {
    padding: 2rem;
  }

  .step {
    padding-left: 2.5rem;
  }
}

.behandling-cta {
  text-align: center;
  margin-top: 4rem;
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

.behandling-cta .cta-button {
  display: inline-flex;
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.behandling-cta .cta-button.primary {
  background: #3d6351;
  color: white;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.15);
}

.behandling-cta .cta-button.primary:hover {
  background: #325343;
  transform: translateY(-2px);
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.2);
}

.behandling-cta .cta-button.secondary {
  background: white;
  color: #3d6351;
  border: 2px solid #3d6351;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.08);
}

.behandling-cta .cta-button.secondary:hover {
  background: rgba(61, 99, 81, 0.05);
  transform: translateY(-2px);
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.12);
}

@media (max-width: 768px) {
  .behandling-cta {
    flex-direction: column;
    gap: 1rem;
    padding: 0 2rem;
  }

  .behandling-cta .cta-button {
    width: 100%;
    justify-content: center;
  }
}
