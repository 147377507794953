.admin-priser {
  padding: 2rem;
  padding-top: 240px;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  background: white;
  padding: 2rem;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  z-index: 100;
}

.admin-header-left {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.back-button {
  background: none;
  border: none;
  color: #3d6351;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.back-button:hover {
  background: rgba(61, 99, 81, 0.1);
}

.save-button {
  background: #3d6351;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background: #2c4b3c;
}

.save-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.success-message {
  background: #4caf50;
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.edit-section {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.edit-section h2 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: #2c1810;
}

.input-group {
  margin-bottom: 1.5rem;
}

.input-group:last-child {
  margin-bottom: 0;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #2c1810;
}

.text-input {
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: #2c1810;
}

.text-input:focus {
  outline: none;
  border-color: #3d6351;
}

.textarea {
  min-height: 100px;
  resize: vertical;
}

.price-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 1rem;
}

.admin-priser .edit-section:first-of-type {
  margin-top: 100px;
}

@media (max-width: 768px) {
  .admin-priser {
    padding: 1rem;
    padding-top: 220px;
  }

  .admin-header {
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
  }

  .admin-header-left {
    width: 100%;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .edit-section {
    padding: 1.5rem;
  }

  .price-inputs {
    grid-template-columns: 1fr;
  }

  .admin-priser .edit-section:first-of-type {
    margin-top: 120px;
  }
}

@media (max-width: 768px) {
  .admin-priser {
    padding-top: 280px;
  }
}