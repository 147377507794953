.behandling-side {
  padding-top: 90px;
  background: #f8fafc;
  min-height: 100vh;
}

.behandling-side__container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.behandling-side__header {
  text-align: center;
  margin-bottom: 4rem;
}

.behandling-side__subtitle {
  display: inline-flex;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #3d6351;
  background: white;
  padding: 0.8rem 2rem;
  border-radius: 100px;
  margin-bottom: 1.5rem;
  font-weight: 600;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.1);
}

.behandling-side__title {
  font-size: 3.5rem;
  color: #2c1810;
  font-weight: 800;
  letter-spacing: -1px;
  margin-bottom: 1rem;
}

.behandling-side__intro {
  font-size: 1.2rem;
  line-height: 1.7;
  color: #5a4a42;
  max-width: 700px;
  margin: 0 auto;
  opacity: 0.9;
}

.behandling-side__content {
  max-width: 1200px;
  margin: 0 auto;
}

.behandling-side__section-title {
  font-size: 2.2rem;
  color: #2c1810;
  margin-bottom: 2rem;
  font-weight: 700;
}

.behandling-side__intro-section {
  margin-bottom: 4rem;
}

.behandling-side__metaphor {
  background: white;
  padding: 2rem;
  border-radius: 1.5rem;
  box-shadow: 0 10px 30px rgba(61, 99, 81, 0.08);
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}

.behandling-side__metaphor-icon {
  font-size: 2.5rem;
  color: #3d6351;
  flex-shrink: 0;
}

.behandling-side__process {
  margin-bottom: 4rem;
}

.behandling-side__steps {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.behandling-side__step {
  background: white;
  padding: 2rem;
  border-radius: 1.5rem;
  box-shadow: 0 10px 30px rgba(61, 99, 81, 0.08);
  position: relative;
}

.behandling-side__step-number {
  position: absolute;
  top: -1rem;
  left: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  background: #3d6351;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.behandling-side__step-title {
  font-size: 1.4rem;
  color: #2c1810;
  margin-bottom: 1rem;
}

.behandling-side__info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 4rem;
}

.behandling-side__info-card {
  background: white;
  padding: 2rem;
  border-radius: 1.5rem;
  box-shadow: 0 10px 30px rgba(61, 99, 81, 0.08);
  text-align: center;
}

.behandling-side__info-icon {
  font-size: 2rem;
  color: #3d6351;
  margin-bottom: 1.5rem;
}

.behandling-side__info-title {
  font-size: 1.3rem;
  color: #2c1810;
  margin-bottom: 1rem;
}

.behandling-side__contact {
  text-align: center;
  background: white;
  padding: 3rem;
  border-radius: 1rem;
  margin-bottom: 6rem;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.08);
}

.behandling-side__contact-text {
  font-size: 1.2rem;
  line-height: 1.7;
  color: #5a4a42;
  text-align: center;
}

.behandling-side__contact p {
  text-align: center;
}

.behandling-side__link {
  color: #3d6351;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
  display: inline-block;
  text-align: center;
}

.behandling-side__link:hover {
  color: #325343;
}

@media (max-width: 992px) {
  .behandling-side__steps {
    grid-template-columns: 1fr;
  }

  .behandling-side__info {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .behandling-side {
    padding: 100px 0 60px;
  }

  .behandling-side__title {
    font-size: 2.8rem;
  }

  .behandling-side__metaphor {
    flex-direction: column;
    text-align: center;
    padding: 1.5rem;
    gap: 1rem;
  }

  .behandling-side__section-title {
    font-size: 2rem;
  }

  .behandling-side__contact {
    padding: 2rem;
  }
}

.behandling-hero {
  height: 600px;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.behandling-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  z-index: 1;
}

.behandling-hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

.behandling-hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
  padding: 0 2rem;
  max-width: 800px;
}

.behandling-hero-content .behandling-side__subtitle {
  background: rgba(255, 255, 255, 0.9);
  margin-bottom: 1.5rem;
}

.behandling-hero-content .behandling-side__title {
  color: white;
  font-size: 3.5rem;
  margin: 0;
  line-height: 1.2;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .behandling-hero {
    height: 400px;
  }

  .behandling-hero-content .behandling-side__title {
    font-size: 2.5rem;
  }
}

.behandling-side__info-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: 4rem 0 6rem;
}

@media (max-width: 768px) {
  .behandling-side__info-cards {
    grid-template-columns: 1fr;
    margin: 3rem 0 4rem;
  }

  .behandling-side__contact {
    margin-bottom: 4rem;
  }
}

.behandling-side__intro-text {
  max-width: 1200px;
  margin: 0 auto 4rem;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.7;
  color: #5a4a42;
}

.behandling-side__metaphor-section {
  margin-bottom: 4rem;
}

.metaphor-box {
  background: white;
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.08);
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.metaphor-icon {
  font-size: 2.5rem;
  color: #3d6351;
  margin-bottom: 1.5rem;
}

.metaphor-box h2 {
  font-size: 2rem;
  color: #2c1810;
  margin-bottom: 1.5rem;
}

.metaphor-box p {
  font-size: 1.15rem;
  line-height: 1.8;
  color: #5a4a42;
  margin-bottom: 1.5rem;
}

.metaphor-box p:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .behandling-side__intro-text {
    padding: 0 1.5rem;
    font-size: 1.1rem;
  }

  .metaphor-box {
    margin: 0 1rem;
    padding: 2rem;
  }

  .metaphor-box h2 {
    font-size: 1.8rem;
  }

  .metaphor-box p {
    font-size: 1.1rem;
  }
}

.explanation-box,
.approach-box {
  display: flex;
  align-items: center;
  gap: 4rem;
  background: white;
  padding: 4rem;
  border-radius: 1.5rem;
  box-shadow: 0 10px 30px rgba(61, 99, 81, 0.08);
  margin-bottom: 4rem;
}

.content-image {
  width: 450px;
  height: 600px;
  object-fit: cover;
  border-radius: 1.5rem;
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.15);
  transition: transform 0.3s ease;
}

.content-image.mobile {
  display: none;
}

.content-image.desktop {
  display: block;
}

.content-image:hover {
  transform: scale(1.02);
}

.content-text {
  flex: 1;
}

.content-text h3 {
  font-size: 2.2rem;
  color: #2c1810;
  margin-bottom: 2rem;
  font-weight: 700;
}

.content-text p {
  font-size: 1.15rem;
  line-height: 1.8;
  color: #5a4a42;
  margin-bottom: 1.5rem;
}

@media (max-width: 992px) {
  .content-image.desktop {
    display: none;
  }

  .content-image.mobile {
    display: block;
    width: 100%;
    height: 400px;
    margin-top: 2rem;
  }

  .explanation-box,
  .approach-box {
    flex-direction: column;
    gap: 0;
    padding: 3rem;
  }

  .content-image {
    width: 100%;
    height: 400px;
  }

  .content-text h3 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 768px) {
  .content-image.mobile {
    height: 350px;
  }

  .explanation-box,
  .approach-box {
    padding: 2rem;
  }

  .content-text h3 {
    font-size: 1.6rem;
  }

  .content-text p {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .content-image.mobile {
    height: 300px;
  }
}

.content-link {
  color: #3d6351;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.content-link:hover {
  color: #325343;
  text-decoration: underline;
}

.loading-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(61, 99, 81, 0.1);
  border-radius: 50%;
  border-top-color: #3d6351;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.expectations-box {
  background: white;
  padding: 4rem;
  border-radius: 1.5rem;
  box-shadow: 0 10px 30px rgba(61, 99, 81, 0.08);
  margin-bottom: 4rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.expectations-box h3 {
  font-size: 2.2rem;
  color: #2c1810;
  margin-bottom: 2rem;
  font-weight: 700;
  position: relative;
  padding-bottom: 1rem;
}

.expectations-box h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: #3d6351;
  border-radius: 2px;
}

.expectations-box p {
  font-size: 1.15rem;
  line-height: 1.8;
  color: #5a4a42;
  margin-bottom: 1.5rem;
}

.expectations-box p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .expectations-box {
    padding: 3rem;
  }

  .expectations-box h3 {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .expectations-box {
    padding: 2rem;
    margin: 0 1rem 3rem;
  }

  .expectations-box h3 {
    font-size: 1.6rem;
  }

  .expectations-box p {
    font-size: 1.1rem;
  }
}
