.admin-forside {
  padding: 2rem;
  padding-top: 240px;
  max-width: 1200px;
  margin: 0 auto;
}

.buttons-editor {
  margin-top: 2rem;
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
}

.buttons-editor h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  color: #3d6351;
}

.button-edit {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.button-edit:last-child {
  margin-bottom: 0;
}

.select-input {
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: #2c1810;
  background: white;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.select-input:focus {
  outline: none;
  border-color: #3d6351;
}

@media (max-width: 768px) {
  .admin-forside {
    padding: 1rem;
    padding-top: 220px;
  }

  .button-edit {
    padding: 1rem;
  }
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  background: white;
  padding: 2rem;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  z-index: 100;
}

.admin-header-left {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.back-button {
  background: none;
  border: none;
  color: #3d6351;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.back-button:hover {
  background: rgba(61, 99, 81, 0.1);
}

@media (max-width: 768px) {
  .admin-header {
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
  }

  .admin-header-left {
    width: 100%;
    justify-content: center;
    margin-bottom: 1rem;
  }
}

.address-group,
.contact-group,
.info-group {
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  border: 1px solid #e2e8f0;
}

.address-group h3,
.contact-group h3,
.info-group h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  color: #3d6351;
}

@media (max-width: 768px) {
  .address-group,
  .contact-group,
  .info-group {
    padding: 1rem;
  }
}

.features-editor {
  margin-top: 2rem;
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
}

.features-editor h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  color: #3d6351;
}

.feature-edit {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.feature-edit:last-child {
  margin-bottom: 0;
}

.feature-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.feature-header h4 {
  font-size: 1.1rem;
  color: #2c1810;
  margin: 0;
}

.feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  background: #f8fafc;
  border-radius: 0.5rem;
  color: #3d6351;
  font-size: 1.2rem;
}

.feature-icon svg {
  width: 1.2rem;
  height: 1.2rem;
}

@media (max-width: 768px) {
  .features-editor {
    padding: 1rem;
  }

  .feature-edit {
    padding: 1rem;
  }
}

.treatment-areas-editor {
  margin-top: 2rem;
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
}

.treatment-areas-editor h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  color: #3d6351;
}

.treatment-area-edit {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.treatment-area-edit:last-child {
  margin-bottom: 0;
}

.area-header {
  margin-bottom: 1rem;
}

.area-header h4 {
  font-size: 1.1rem;
  color: #2c1810;
  margin: 0;
}

@media (max-width: 768px) {
  .treatment-areas-editor {
    padding: 1rem;
  }

  .treatment-area-edit {
    padding: 1rem;
  }
}

.services-editor,
.steps-editor,
.treatment-buttons-editor {
  margin-top: 2rem;
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
}

.services-editor h3,
.steps-editor h3,
.treatment-buttons-editor h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  color: #3d6351;
}

.service-edit,
.step-edit {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.service-edit:last-child,
.step-edit:last-child {
  margin-bottom: 0;
}

.service-header,
.step-header {
  margin-bottom: 1rem;
}

.service-header h4,
.step-header h4 {
  font-size: 1.1rem;
  color: #2c1810;
  margin: 0;
}

@media (max-width: 768px) {
  .services-editor,
  .steps-editor,
  .treatment-buttons-editor {
    padding: 1rem;
  }

  .service-edit,
  .step-edit {
    padding: 1rem;
  }
}

.resources-editor {
  margin-top: 2rem;
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
}

.resources-editor h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  color: #3d6351;
}

.resource-edit {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.resource-edit:last-child {
  margin-bottom: 0;
}

.resource-header {
  margin-bottom: 1rem;
}

.resource-header h4 {
  font-size: 1.1rem;
  color: #2c1810;
  margin: 0;
}

@media (max-width: 768px) {
  .resources-editor {
    padding: 1rem;
  }

  .resource-edit {
    padding: 1rem;
  }
}

.email-group,
.clinic-group,
.hours-group,
.policies-editor {
  margin-top: 2rem;
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
}

.email-group h3,
.clinic-group h3,
.hours-group h3,
.policies-editor h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  color: #3d6351;
}

.policy-edit {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.policy-edit:last-child {
  margin-bottom: 0;
}

.policy-header {
  margin-bottom: 1rem;
}

.policy-header h4 {
  font-size: 1.1rem;
  color: #2c1810;
  margin: 0;
}

@media (max-width: 768px) {
  .email-group,
  .clinic-group,
  .hours-group,
  .policies-editor {
    padding: 1rem;
  }

  .policy-edit {
    padding: 1rem;
  }
}

.milestones-editor,
.specialties-editor {
  margin-top: 2rem;
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
}

.milestones-editor h3,
.specialties-editor h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  color: #3d6351;
}

.milestone-edit {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.milestone-edit:last-child {
  margin-bottom: 0;
}

.milestone-header {
  margin-bottom: 1rem;
}

.milestone-header h4 {
  font-size: 1.1rem;
  color: #2c1810;
  margin: 0;
}

@media (max-width: 768px) {
  .milestones-editor,
  .specialties-editor {
    padding: 1rem;
  }

  .milestone-edit {
    padding: 1rem;
  }
}

.faq-editor {
  margin-top: 2rem;
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
}

.faq-editor h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  color: #3d6351;
}

.faq-item-edit {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.faq-item-edit:last-child {
  margin-bottom: 0;
}

.faq-item-header {
  margin-bottom: 1rem;
}

.faq-item-header h4 {
  font-size: 1.1rem;
  color: #2c1810;
  margin: 0;
}

@media (max-width: 768px) {
  .faq-editor {
    padding: 1rem;
  }

  .faq-item-edit {
    padding: 1rem;
  }
}

.editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.editor-header h3 {
  margin: 0;
}

.add-button {
  background: #3d6351;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.add-button:hover {
  background: #2c4b3c;
}

.remove-button {
  background: #dc3545;
  color: white;
  border: none;
  padding: 0.3rem 0.8rem;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.3s ease;
}

.remove-button:hover {
  background: #bd2130;
}

.faq-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Tilføj padding-top til første edit-section for at kompensere for fixed header */
.admin-forside .edit-section:first-of-type {
  margin-top: 100px;
}

@media (max-width: 768px) {
  .admin-header {
    padding: 1rem;
  }
  
  .admin-forside .edit-section:first-of-type {
    margin-top: 120px;
  }
}

.help-text {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
  font-style: italic;
}

.item-description {
  margin-top: 1rem;
  width: 100%;
}

.item-description textarea {
  width: 100%;
  min-height: 80px;
  padding: 0.8rem;
  border: 2px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  resize: vertical;
}

.item-description textarea:focus {
  outline: none;
  border-color: #3d6351;
}

.list-item {
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  border: 2px solid #e2e8f0;
}

.list-item:last-child {
  margin-bottom: 0;
}

.item-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.milestone-item,
.specialty-item {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  margin-bottom: 1rem;
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.milestone-item:last-child,
.specialty-item:last-child {
  margin-bottom: 0;
}

.milestone-inputs {
  display: flex;
  gap: 1rem;
  flex: 1;
}

.milestone-inputs .input-group {
  margin-bottom: 0;
}

.milestone-inputs .input-group:first-child {
  width: 100px;
}

.milestone-inputs .input-group:last-child {
  flex: 1;
}

.specialty-item .input-group {
  flex: 1;
  margin-bottom: 0;
}

.editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.editor-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #3d6351;
}

@media (max-width: 768px) {
  .admin-forside {
    padding-top: 280px;
  }
}