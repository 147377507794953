.kontakt {
  position: relative;
  padding: 8rem 0;
  background: #f8fafc;
  overflow: hidden;
}

.kontakt-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.kontakt-header {
  text-align: center;
  margin-bottom: 4rem;
}

.kontakt-subtitle {
  display: inline-flex;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #3d6351;
  background: white;
  padding: 0.8rem 2rem;
  border-radius: 100px;
  margin-bottom: 1.5rem;
  font-weight: 600;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.1);
}

.kontakt-header h2 {
  font-size: 3.5rem;
  line-height: 1.1;
  margin-bottom: 1rem;
  color: #2c1810;
  font-weight: 800;
  letter-spacing: -1px;
}

.kontakt-header p {
  font-size: 1.2rem;
  line-height: 1.7;
  color: #5a4a42;
  max-width: 600px;
  margin: 0 auto;
  opacity: 0.9;
}

.kontakt-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  max-width: 1000px;
  margin: 0 auto;
}

.info-card {
  background: white;
  padding: 2.5rem;
  border-radius: 1.5rem;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 260px;
}

.info-icon {
  width: 60px;
  height: 60px;
  background: rgba(61, 99, 81, 0.08);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3d6351;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.info-card h3 {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #2c1810;
}

.info-card p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #3d6351;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.info-note {
  font-size: 0.95rem;
  color: #5a4a42;
  opacity: 0.8;
}

.info-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.12);
}

.info-card:hover .info-icon {
  background: rgba(61, 99, 81, 0.12);
  transform: scale(1.1);
}

@media (max-width: 1200px) {
  .kontakt-header h2 {
    font-size: 3.2rem;
  }

  .kontakt-info {
    gap: 2rem;
  }
}

@media (max-width: 992px) {
  .kontakt {
    padding: 6rem 0;
  }

  .kontakt-container {
    padding: 0 1.5rem;
  }

  .kontakt-header h2 {
    font-size: 2.8rem;
  }

  .kontakt-header p {
    font-size: 1.1rem;
    max-width: 500px;
  }

  .kontakt-info {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }

  .info-card {
    padding: 2rem;
    min-height: 220px;
  }

  .info-icon {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
  }

  .info-card h3 {
    font-size: 1.3rem;
  }

  .info-card p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .kontakt {
    padding: 5rem 0;
  }

  .kontakt-container {
    padding: 0 1rem;
  }

  .kontakt-header {
    margin-bottom: 3rem;
  }

  .kontakt-header h2 {
    font-size: 2.4rem;
    margin-bottom: 0.8rem;
  }

  .kontakt-subtitle {
    font-size: 0.8rem;
    padding: 0.6rem 1.5rem;
    margin-bottom: 1rem;
  }

  .kontakt-info {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .info-card {
    padding: 1.5rem;
    min-height: auto;
  }

  .info-icon {
    width: 45px;
    height: 45px;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .info-card h3 {
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
  }

  .info-card p {
    font-size: 0.95rem;
  }

  .info-note {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .kontakt {
    padding: 4rem 0;
  }

  .kontakt-header h2 {
    font-size: 2.2rem;
  }

  .kontakt-header p {
    font-size: 1rem;
    line-height: 1.5;
  }

  .info-card {
    padding: 1.2rem;
  }

  .praktisk-info {
    margin-top: 2rem;
  }

  .info-box {
    padding: 1.2rem;
  }

  .info-box h3 {
    font-size: 1.2rem;
  }

  .info-box p {
    font-size: 0.9rem;
  }
}

@media (max-height: 500px) and (orientation: landscape) {
  .kontakt {
    padding: 4rem 0;
  }

  .kontakt-info {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  .info-card {
    padding: 1rem;
  }

  .praktisk-info {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

.profile-section {
  margin-top: 6rem;
  background: white;
  border-radius: 2rem;
  padding: 4rem;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 4rem;
  box-shadow: 0 10px 30px rgba(61, 99, 81, 0.08);
}

.profile-image-container {
  position: relative;
  max-width: 100%;
}

.profile-image {
  width: 100%;
  aspect-ratio: 3/4;
  min-height: 500px;
  object-fit: cover;
  border-radius: 1.5rem;
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.15);
  margin-bottom: 2rem;
}

.profile-credentials {
  background: #f8fafc;
  padding: 2rem;
  border-radius: 1rem;
}

.credential-item {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(61, 99, 81, 0.1);
  text-align: left;
}

.credential-item:last-child {
  border-bottom: none;
}

.credential-item .year {
  font-size: 1.2rem;
  font-weight: 700;
  color: #3d6351;
  flex-shrink: 0;
}

.credential-item .detail {
  font-size: 1rem;
  color: #5a4a42;
  line-height: 1.5;
  text-align: left;
}

.profile-content h2 {
  font-size: 2.8rem;
  color: #2c1810;
  margin-bottom: 2rem;
  font-weight: 700;
}

.profile-mobile-title {
  display: none;
  font-size: 2.8rem;
  color: #2c1810;
  margin-bottom: 2rem;
  font-weight: 700;
  text-align: center;
}

.profile-content h3 {
  font-size: 1.6rem;
  color: #3d6351;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.profile-content h4 {
  font-size: 1.2rem;
  color: #2c1810;
  margin: 2rem 0 1rem;
  font-weight: 600;
}

.profile-text p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #5a4a42;
  margin-bottom: 1.5rem;
}

.profile-specialties ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  list-style: none;
  padding: 0;
}

.profile-specialties li {
  font-size: 1rem;
  color: #5a4a42;
  padding: 0.8rem 1.2rem;
  background: rgba(61, 99, 81, 0.08);
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
}

@media (max-width: 1200px) {
  .profile-section {
    padding: 3rem;
    gap: 3rem;
  }

  .profile-content h2 {
    font-size: 2.4rem;
  }

  .profile-content h3 {
    font-size: 1.4rem;
  }

  .profile-text p {
    font-size: 1rem;
  }
}

@media (max-width: 992px) {
  .profile-section {
    grid-template-columns: 1fr;
    padding: 2.5rem;
    gap: 2rem;
    margin-top: 4rem;
  }

  .profile-image-container {
    max-width: 500px;
    margin: 0 auto;
  }

  .profile-image {
    aspect-ratio: 3/4;
    min-height: 400px;
  }

  .profile-credentials {
    max-width: 500px;
    margin: 0 auto;
  }

  .profile-content {
    max-width: 500px;
    margin: 0 auto;
  }

  .credential-item {
    padding: 0.8rem 0;
  }

  .credential-item .year {
    min-width: 60px;
  }

  .profile-content h2 {
    display: none;
  }

  .profile-mobile-title {
    display: block;
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 768px) {
  .profile-section {
    padding: 2rem;
    margin-top: 3rem;
  }

  .profile-image-container {
    max-width: 100%;
  }

  .profile-image {
    aspect-ratio: 3/4;
    min-height: 350px;
  }

  .profile-credentials {
    padding: 1.5rem;
  }

  .credential-item {
    padding: 0.8rem 0;
  }

  .credential-item .year {
    min-width: 60px;
  }

  .profile-mobile-title {
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 480px) {
  .profile-section {
    padding: 1.5rem;
    margin-top: 2.5rem;
  }

  .profile-image {
    aspect-ratio: 3/4;
    min-height: 300px;
    border-radius: 1rem;
  }

  .profile-credentials {
    padding: 1.2rem;
    border-radius: 0.8rem;
  }

  .credential-item {
    flex-direction: column;
    gap: 0.3rem;
    padding: 0.8rem 0;
    text-align: left;
  }

  .credential-item .year {
    min-width: auto;
    margin-bottom: 0.2rem;
  }

  .profile-mobile-title {
    font-size: 2rem;
    margin-bottom: 1.2rem;
  }

  .profile-specialties li {
    min-height: 42px;
    padding: 0.6rem 1rem;
  }

  .credential-item .detail {
    text-align: left;
  }
}

@media (max-height: 500px) and (orientation: landscape) {
  .profile-section {
    grid-template-columns: 0.8fr 1.2fr;
    padding: 2rem;
    gap: 2rem;
  }

  .profile-image {
    aspect-ratio: 3/4;
    min-height: 250px;
  }

  .profile-image-container {
    max-width: none;
  }
}

.praktisk-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 4rem;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.info-box {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.08);
}

.info-box h3 {
  font-size: 1.4rem;
  color: #2c1810;
  margin-bottom: 1rem;
  font-weight: 600;
}

.info-box p {
  font-size: 1rem;
  line-height: 1.6;
  color: #5a4a42;
}

@media (max-width: 768px) {
  .praktisk-info {
    grid-template-columns: 1fr;
    margin-top: 3rem;
    padding: 0 1rem;
  }

  .info-box {
    padding: 1.5rem;
  }
}
