.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  background: url('../../images/Louise Munk-81.jpg') center center/cover;
  padding: 0;
  overflow: hidden;
}

/* Moderne baggrundselementer */
.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(61, 99, 81, 0.10) 0%,
    rgba(61, 99, 81, 0.15) 60%,
    rgba(255, 236, 210, 0.20) 100%
  );
  z-index: 1;
}

.hero::after {
  display: none;
}

.hero-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: 1.5fr 0.8fr;
  gap: 4rem;
  align-items: center;
  position: relative;
  z-index: 2;
}

.hero-content {
  color: white;
  max-width: 800px;
  position: relative;
  z-index: 3;
}

.profile-container {
  position: relative;
  z-index: 3;
}

.profile-wrapper {
  position: relative;
  width: 380px;
  height: 380px;
  animation: float 10s ease-in-out infinite;
}

.profile-image {
  width: 80%;
  height: 80%;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  object-fit: cover;
  border: 4px solid rgba(255, 255, 255, 0.9);
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.2),
    0 0 0 15px rgba(255, 255, 255, 0.1);
  animation: morphing 30s ease-in-out infinite;
  transition: all 0.5s ease;
}

.hero-subtitle {
  display: inline-flex;
  align-items: center;
  font-size: 0.95rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #3d6351;
  background: white;
  padding: 0.8rem 1.6rem;
  border-radius: 100px;
  margin-bottom: 1.5rem;
  font-weight: 600;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  animation: slideIn 1s ease-out forwards;
}

.hero-content h1 {
  font-size: 4.2rem;
  line-height: 1.1;
  margin-bottom: 1.2rem;
  font-weight: 800;
  letter-spacing: -2px;
  animation: fadeUp 1s ease-out forwards;
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.8));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-content p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 400;
  animation: fadeUp 1s ease-out 0.2s forwards;
  opacity: 0;
}

.hero-buttons {
  display: flex;
  gap: 1.2rem;
  animation: fadeUp 1s ease-out 0.4s forwards;
  opacity: 0;
}

.cta-button {
  padding: 0.9rem 2.2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.cta-button.primary {
  background: white;
  color: #3d6351;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.cta-button.primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.cta-button.secondary {
  background: transparent;
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.cta-button.secondary:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
  transform: translateY(-2px);
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

@keyframes morphing {
  0%, 100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
  }
  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ... media queries opdateres tilsvarende ... */

@media (min-height: 900px) {
  .hero {
    min-height: 100vh;
    padding: 6rem 0;
  }
  
  .profile-wrapper {
    width: 380px;
    height: 380px;
  }
  
  .hero-content h1 {
    font-size: 4.8rem;
  }
}

/* Tilføj media queries for responsivitet */
@media (max-width: 1200px) {
  .hero-content h1 {
    font-size: 3.8rem;
  }
  
  .hero-container {
    grid-template-columns: 1.2fr 0.8fr;
    gap: 3rem;
  }
}

@media (max-width: 992px) {
  .hero {
    background-position: 80% center;
  }

  .hero-container {
    grid-template-columns: 1fr;
    text-align: center;
    padding: 0 1.5rem;
  }

  .hero-content {
    max-width: 600px;
    margin: 0 auto;
  }

  .hero-content h1 {
    font-size: 3.4rem;
  }

  .hero-buttons {
    justify-content: center;
  }

  .profile-container {
    display: none;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 100vh;
    padding: 0;
    background-position: center center;
  }

  .hero-content h1 {
    font-size: 2.8rem;
    letter-spacing: -1px;
  }

  .hero-subtitle {
    font-size: 0.85rem;
    letter-spacing: 3px;
    padding: 0.7rem 1.4rem;
  }

  .hero-content p {
    font-size: 1.1rem;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .cta-button {
    width: 100%;
    text-align: center;
    padding: 1rem 2rem;
  }
}

@media (max-width: 480px) {
  .hero {
    height: 100vh;
    padding: 0;
    background-position: center center;
  }

  .hero-container {
    padding: 0 1rem;
  }

  .hero-content h1 {
    font-size: 2.4rem;
  }

  .hero-content p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .hero-subtitle {
    font-size: 0.8rem;
    letter-spacing: 2px;
    padding: 0.6rem 1.2rem;
    margin-bottom: 1rem;
  }
}

/* Tilføj support for højere skærme */
@media (min-height: 900px) {
  .hero {
    min-height: 100vh;
  }
}

/* Tilføj support for landscape mode på mobile */
@media (max-height: 500px) and (orientation: landscape) {
  .hero {
    height: 100vh;
    padding: 0;
  }

  .hero-container {
    padding-top: 3rem;
  }
}
