.priser-page {
  padding-top: 90px;
  background: #f8fafc;
  min-height: 100vh;
}

.priser-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 2rem;
}

.priser-header {
  text-align: center;
  margin-bottom: 4rem;
}

.priser-header h1 {
  font-size: 3.5rem;
  color: #2c1810;
  font-weight: 800;
  letter-spacing: -1px;
}

.priser-section {
  margin-bottom: 4rem;
}

.priser-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: 2rem 0;
}

.pris-card {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.08);
}

.pris-card h3 {
  color: #3d6351;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.pris-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pris-card li {
  font-size: 1.1rem;
  color: #5a4a42;
  margin-bottom: 0.8rem;
  line-height: 1.6;
}

.note {
  text-align: center;
  color: #5a4a42;
  font-size: 1.1rem;
  margin-top: 2rem;
  font-style: italic;
}

.info-section {
  background: white;
  padding: 2.5rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.08);
}

.info-section h2 {
  color: #3d6351;
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.info-section p {
  color: #5a4a42;
  font-size: 1.1rem;
  line-height: 1.7;
  margin: 0;
}

.priser-subtitle {
  display: inline-flex;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #3d6351;
  background: white;
  padding: 0.8rem 2rem;
  border-radius: 100px;
  margin-bottom: 1.5rem;
  font-weight: 600;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.1);
}

.priser-header p {
  font-size: 1.2rem;
  line-height: 1.7;
  color: #5a4a42;
  max-width: 600px;
  margin: 1rem auto 0;
  opacity: 0.9;
}

.pris-details {
  margin-top: 1.5rem;
}

.pris-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0;
  border-bottom: 1px solid rgba(61, 99, 81, 0.1);
}

.pris-item:last-child {
  border-bottom: none;
}

.pris-label {
  color: #5a4a42;
  font-size: 1.1rem;
}

.pris-amount {
  color: #3d6351;
  font-size: 1.2rem;
  font-weight: 700;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
}

.priser-cta {
  text-align: center;
  background: white;
  padding: 3rem;
  border-radius: 1rem;
  margin-top: 4rem;
  margin-bottom: 6rem;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.08);
}

.priser-cta p {
  font-size: 1.3rem;
  color: #2c1810;
  margin-bottom: 1.5rem;
  text-align: center;
}

.priser-cta .cta-button {
  display: inline-flex;
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  background: #3d6351;
  color: white;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.15);
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 200px;
}

.priser-cta .cta-button:hover {
  background: #325343;
  transform: translateY(-2px);
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.2);
}

@media (max-width: 768px) {
  .priser-page {
    padding: 100px 0 60px;
  }

  .priser-header h1 {
    font-size: 2.8rem;
  }

  .priser-cards {
    grid-template-columns: 1fr;
  }

  .info-section {
    padding: 2rem;
  }

  .info-grid {
    grid-template-columns: 1fr;
  }

  .priser-cta {
    padding: 2rem;
    margin-top: 3rem;
  }

  .priser-cta p {
    font-size: 1.2rem;
  }
}

.priser-hero {
  height: 600px;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.priser-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  z-index: 1;
}

.priser-hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

.priser-hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
  padding: 0 2rem;
  max-width: 800px;
}

.priser-hero-content .priser-subtitle {
  background: rgba(255, 255, 255, 0.9);
  margin-bottom: 1.5rem;
}

.priser-hero-content h1 {
  color: white;
  font-size: 3.5rem;
  margin: 0;
  line-height: 1.2;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .priser-hero {
    height: 400px;
  }

  .priser-hero-content h1 {
    font-size: 2.5rem;
  }
}

.priser-content {
  padding-bottom: 6rem;
}

.loading-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(61, 99, 81, 0.1);
  border-radius: 50%;
  border-top-color: #3d6351;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
