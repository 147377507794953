.faq {
  position: relative;
  padding: 0rem 0 8rem;
  background: #f8fafc;
  overflow: hidden;
}

.faq-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 2rem;
}

.faq-header {
  text-align: center;
  margin-bottom: 4rem;
}

.faq-subtitle {
  display: inline-flex;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #3d6351;
  background: white;
  padding: 0.8rem 2rem;
  border-radius: 100px;
  margin-bottom: 1.5rem;
  font-weight: 600;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.1);
}

.faq-header h2 {
  font-size: 3.5rem;
  line-height: 1.1;
  margin-bottom: 1rem;
  color: #2c1810;
  font-weight: 800;
  letter-spacing: -1px;
}

.faq-header p {
  font-size: 1.2rem;
  line-height: 1.7;
  color: #5a4a42;
  max-width: 600px;
  margin: 0 auto;
  opacity: 0.9;
}

.faq-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-item {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(61, 99, 81, 0.05);
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.faq-question h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2c1810;
  margin: 0;
}

.faq-icon {
  color: #3d6351;
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.faq-answer p {
  margin: 0;
  padding-top: 1rem;
  color: #5a4a42;
  line-height: 1.6;
  font-size: 1.1rem;
}

.faq-item.active {
  background: white;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.08);
}

.faq-item.active .faq-icon {
  transform: rotate(180deg);
}

.faq-item.active .faq-answer {
  max-height: 300px;
}

.faq-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.08);
}

@media (max-width: 768px) {
  .faq {
    padding: 6rem 0;
  }

  .faq-header h2 {
    font-size: 2.8rem;
  }

  .faq-question h3 {
    font-size: 1.1rem;
  }

  .faq-answer p {
    font-size: 1rem;
  }
}

.faq-answer a {
  color: #3d6351;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.faq-answer a:hover {
  color: #325343;
  text-decoration: underline;
}
