.admin-behandling {
  padding: 2rem;
  padding-top: 120px;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.admin-header-left {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.back-button {
  background: none;
  border: none;
  color: #3d6351;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.back-button:hover {
  background: rgba(61, 99, 81, 0.1);
}

.admin-header h1 {
  margin: 0;
  font-size: 2rem;
  color: #2c1810;
}

.save-button {
  background: #3d6351;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.save-button:hover {
  background: #325343;
}

.save-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.edit-section {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.edit-section h2 {
  margin: 0 0 1.5rem 0;
  font-size: 1.5rem;
  color: #2c1810;
}

.input-group {
  margin-bottom: 1.5rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #5a4a42;
}

.text-input {
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.text-input:focus {
  outline: none;
  border-color: #3d6351;
}

.success-message {
  background: #dcfce7;
  color: #166534;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
  animation: fadeIn 0.3s ease;
}

.admin-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1.2rem;
  color: #5a4a42;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .admin-behandling {
    padding: 1rem;
  }

  .admin-header {
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
  }

  .admin-header-left {
    width: 100%;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .admin-header h1 {
    font-size: 1.8rem;
  }

  .edit-section {
    padding: 1.5rem;
  }
}

.text-input.textarea {
  min-height: 120px;
  resize: vertical;
  line-height: 1.5;
  font-family: inherit;
}

.text-input.textarea:focus {
  border-color: #3d6351;
}

.process-steps-editor {
  display: grid;
  gap: 2rem;
  margin-top: 2rem;
}

.process-step-edit {
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
}

.step-header {
  margin-bottom: 1rem;
}

.step-header h3 {
  font-size: 1.2rem;
  color: #3d6351;
  margin: 0;
}

@media (max-width: 768px) {
  .process-steps-editor {
    gap: 1.5rem;
  }
  
  .process-step-edit {
    padding: 1rem;
  }
}

.info-cards-editor {
  display: grid;
  gap: 2rem;
  margin-top: 2rem;
}

.info-card-edit {
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
}

.card-header {
  margin-bottom: 1rem;
}

.card-header h3 {
  font-size: 1.2rem;
  color: #3d6351;
  margin: 0;
}

@media (max-width: 768px) {
  .info-cards-editor {
    gap: 1.5rem;
  }
  
  .info-card-edit {
    padding: 1rem;
  }
} 

@media (max-width: 768px) {
  .admin-behandling {
    padding-top: 280px;
  }
}