.inspiration-page {
  padding-top: 90px;
  background: #f8fafc;
  min-height: 100vh;
}

.inspiration-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  padding-bottom: 6rem;
}

.inspiration-header {
  text-align: center;
  margin-bottom: 4rem;
}

.inspiration-header h1 {
  font-size: 3.5rem;
  color: #2c1810;
  font-weight: 800;
  letter-spacing: -1px;
  margin-bottom: 1rem;
}

.inspiration-subtitle {
  display: inline-flex;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #3d6351;
  background: white;
  padding: 0.8rem 2rem;
  border-radius: 100px;
  margin-bottom: 1.5rem;
  font-weight: 600;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.1);
}

.inspiration-header p {
  font-size: 1.2rem;
  line-height: 1.7;
  color: #5a4a42;
  max-width: 600px;
  margin: 0 auto;
  opacity: 0.9;
}

.category-filters {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.category-button {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 100px;
  background: white;
  color: #3d6351;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(61, 99, 81, 0.1);
}

.category-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(61, 99, 81, 0.15);
}

.category-button.active {
  background: #3d6351;
  color: white;
}

.inspiration-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
}

.inspiration-card {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.08);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.card-icon {
  width: 50px;
  height: 50px;
  background: rgba(61, 99, 81, 0.08);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3d6351;
  font-size: 1.4rem;
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content-type {
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 2px;
  color: #3d6351;
  margin-bottom: 0.5rem;
}

.inspiration-card h3 {
  font-size: 1.4rem;
  color: #2c1810;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.author {
  font-size: 0.95rem;
  color: #5a4a42;
  margin-bottom: 1rem;
}

.description {
  font-size: 1rem;
  line-height: 1.6;
  color: #5a4a42;
  margin-bottom: 1rem;
  flex: 1;
}

.category-tag {
  display: inline-flex;
  padding: 0.4rem 1rem;
  background: rgba(61, 99, 81, 0.08);
  color: #3d6351;
  border-radius: 100px;
  font-size: 0.85rem;
  font-weight: 600;
  align-self: flex-start;
}

.inspiration-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.12);
}

@media (max-width: 768px) {
  .inspiration-page {
    padding: 100px 0 60px;
  }

  .inspiration-header h1 {
    font-size: 2.8rem;
  }

  .inspiration-grid {
    grid-template-columns: 1fr;
  }

  .category-filters {
    gap: 0.8rem;
  }

  .category-button {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
}

.search-and-filter {
  background: white;
  padding: 2rem;
  border-radius: 1.5rem;
  box-shadow: 0 10px 30px rgba(61, 99, 81, 0.08);
  margin-bottom: 3rem;
}

.search-section {
  margin-bottom: 2rem;
  position: relative;
}

.search-container {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.search-icon {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: #3d6351;
  font-size: 1.4rem;
  opacity: 0.7;
}

.search-input {
  width: 100%;
  padding: 1.2rem 1.5rem 1.2rem 4rem;
  border: 2px solid rgba(61, 99, 81, 0.1);
  border-radius: 100px;
  background: #f8fafc;
  font-size: 1.1rem;
  color: #2c1810;
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: #3d6351;
  background: white;
  box-shadow: 0 8px 20px rgba(61, 99, 81, 0.1);
}

.search-input::placeholder {
  color: #5a4a42;
  opacity: 0.6;
}

.filter-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.filter-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(61, 99, 81, 0.1);
  margin-bottom: 1rem;
}

.filter-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2c1810;
}

.filter-count {
  background: rgba(61, 99, 81, 0.08);
  color: #3d6351;
  padding: 0.3rem 0.8rem;
  border-radius: 100px;
  font-size: 0.9rem;
  font-weight: 600;
  position: relative;
}

.filter-count::after {
  content: attr(data-selected);
  position: absolute;
  top: -8px;
  right: -8px;
  background: #3d6351;
  color: white;
  font-size: 0.7rem;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s ease;
}

.filter-count[data-selected]:not([data-selected="0"])::after {
  opacity: 1;
  transform: scale(1);
}

.category-filters {
  display: flex;
  gap: 0.8rem;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.category-button {
  padding: 0.8rem 1.5rem;
  border: 2px solid rgba(61, 99, 81, 0.1);
  border-radius: 100px;
  background: transparent;
  color: #5a4a42;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.category-button:hover {
  transform: translateY(-2px);
  border-color: #3d6351;
  color: #3d6351;
}

.category-button.active {
  background: #3d6351;
  color: white;
  border-color: #3d6351;
}

.type-filters {
  display: flex;
  gap: 0.8rem;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.type-button {
  padding: 0.8rem 1.5rem;
  border: 2px solid rgba(61, 99, 81, 0.1);
  border-radius: 100px;
  background: transparent;
  color: #5a4a42;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  position: relative;
  overflow: hidden;
}

.type-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(61, 99, 81, 0.05);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.type-button:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.type-button.active {
  background: #3d6351;
  color: white;
  border-color: #3d6351;
}

.type-button.active::before {
  display: none;
}

.type-button svg {
  position: relative;
  z-index: 1;
}

.type-button span {
  position: relative;
  z-index: 1;
}

@media (max-width: 768px) {
  .search-and-filter {
    padding: 1.5rem;
    margin: 0 1rem 2rem 1rem;
  }

  .search-container {
    padding: 0;
  }

  .search-input {
    padding: 1rem 1rem 1rem 3.5rem;
    font-size: 1rem;
  }

  .search-icon {
    left: 1.2rem;
    font-size: 1.2rem;
  }

  .filter-section {
    gap: 1.5rem;
  }

  .category-button, 
  .type-button {
    padding: 0.7rem 1.2rem;
    font-size: 0.9rem;
  }

  .type-button svg {
    font-size: 1.1rem;
  }
}

.inspiration-hero {
  height: 600px;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inspiration-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  z-index: 1;
}

.inspiration-hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

.inspiration-hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
  padding: 0 2rem;
  max-width: 800px;
}

.inspiration-hero-content .inspiration-subtitle {
  background: rgba(255, 255, 255, 0.9);
  margin-bottom: 1.5rem;
}

.inspiration-hero-content h1 {
  color: white;
  font-size: 3.5rem;
  margin: 0;
  line-height: 1.2;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .inspiration-hero {
    height: 400px;
  }

  .inspiration-hero-content h1 {
    font-size: 2.5rem;
  }
}

.resource-link {
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: color 0.2s ease;
}

.resource-link:hover {
  color: #3d6351;
}

.external-link-icon {
  font-size: 0.8em;
  opacity: 0.7;
}

.inspiration-card:hover .resource-link {
  color: #3d6351;
}
