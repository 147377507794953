.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  z-index: 1000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.header-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  text-decoration: none;
  text-align: left;
}

.logo h1 {
  font-size: 1.8rem;
  color: #3d6351;
  font-weight: 600;
  letter-spacing: -0.5px;
  margin: 0;
}

.logo p {
  margin: 0;
  font-size: 0.9rem;
  color: #5a4a42;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 2px;
}

.nav-menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 4rem;
  align-items: center;
}

.nav-menu li {
  display: flex;
  align-items: center;
}

.nav-menu a {
  text-decoration: none;
  color: #3d6351;
  font-size: 1.05rem;
  font-weight: 500;
  position: relative;
  padding: 0.5rem 0;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.nav-menu a::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #3d6351, #325343);
  transition: width 0.3s ease;
  border-radius: 2px;
}

.nav-menu a:hover {
  color: #3d6351;
}

.nav-menu a:hover::after {
  width: 100%;
}

.mobile-menu-button {
  display: none;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 1001;
}

.mobile-menu-button span {
  width: 26px;
  height: 2px;
  background-color: #3d6351;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.nav-menu .cta-button {
  display: inline-flex;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  background: #3d6351;
  color: white;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.15);
}

.nav-menu .cta-button:hover {
  background: #325343;
  transform: translateY(-2px);
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.2);
}

.nav-menu .cta-button::after {
  display: none;
}

.nav-menu .home-link {
  color: #3d6351;
  font-weight: 500;
  position: relative;
  padding: 0.5rem 0;
}

.nav-menu .home-link::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #3d6351, #325343);
  transition: width 0.3s ease;
  border-radius: 2px;
}

.nav-menu .home-link:hover {
  color: #3d6351;
}

.nav-menu .home-link:hover::after {
  width: 100%;
}

@media (max-width: 1050px) {
  .header-container {
    height: 90px;
  }

  .mobile-menu-button {
    display: flex;
  }

  .nav-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    padding: 6rem 2rem 2rem 2rem;
    transition: all 0.3s ease;
  }

  .nav-menu.active {
    display: block;
  }

  .nav-menu ul {
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
  }

  .nav-menu li {
    height: auto;
  }

  .nav-menu a {
    font-size: 1.2rem;
    color: #1e293b;
  }

  .mobile-menu-button.active span:nth-child(1) {
    transform: rotate(45deg) translate(6px, 6px);
  }

  .mobile-menu-button.active span:nth-child(2) {
    opacity: 0;
  }

  .mobile-menu-button.active span:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -7px);
  }

  .nav-menu .cta-button {
    margin-top: 1rem;
  }

  .nav-menu .home-link {
    font-size: 1.2rem;
    color: #1e293b;
  }
}
