.om {
  position: relative;
  padding: 10rem 0;
  background: #fff;
  margin-top: 0;
  overflow: hidden;
}

.om::before {
  display: none;
}

.om-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  z-index: 2;
}

.om-content {
  max-width: 1200px;
  margin: 0 auto;
}

.om-header {
  text-align: center;
  margin-bottom: 6rem;
}

.om-subtitle {
  display: inline-flex;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #3d6351;
  background: white;
  padding: 0.8rem 2rem;
  border-radius: 100px;
  margin-bottom: 2rem;
  font-weight: 600;
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.08);
}

.om-header h2 {
  font-size: 3.8rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  color: #2c1810;
  font-weight: 800;
  letter-spacing: -1px;
}

.om-header p {
  font-size: 1.2rem;
  line-height: 1.7;
  color: #5a4a42;
  max-width: 650px;
  margin: 0 auto;
  opacity: 0.9;
}

.om-features {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.feature-item {
  background: white;
  padding: 2.5rem;
  border-radius: 1.5rem;
  position: relative;
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.05);
  transition: all 0.3s ease;
}

.feature-icon {
  width: 52px;
  height: 52px;
  background: rgba(61, 99, 81, 0.08);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3d6351;
  font-size: 1.4rem;
  flex-shrink: 0;
  transition: all 0.3s ease;
}

.feature-text {
  flex: 1;
}

.feature-text h3 {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #2c1810;
}

.feature-text p {
  font-size: 1rem;
  line-height: 1.6;
  color: #5a4a42;
  margin: 0;
  opacity: 0.9;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(61, 99, 81, 0.08);
}

.feature-item:hover .feature-icon {
  background: rgba(61, 99, 81, 0.12);
  transform: scale(1.1);
}

@media (max-width: 1200px) {
  .om-header h2 {
    font-size: 3.4rem;
  }

  .profile-section {
    gap: 3rem;
    padding: 2.5rem;
  }
}

@media (max-width: 992px) {
  .om {
    padding: 6rem 0;
  }

  .om-header {
    margin-bottom: 4rem;
  }

  .om-header h2 {
    font-size: 3rem;
  }

  .profile-section {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 2rem;
    padding: 2rem;
  }

  .profile-image {
    max-width: 300px;
    margin: 0 auto;
  }

  .profile-content h2 {
    font-size: 2.4rem;
  }

  .om-features {
    grid-template-columns: 1fr;
    max-width: 550px;
    margin: 0 auto;
    gap: 1.5rem;
  }

  .contact-info {
    display: inline-block;
    text-align: left;
    margin-top: 1.5rem;
  }
}

@media (max-width: 768px) {
  .om {
    padding: 5rem 0;
  }

  .om-container {
    padding: 0 1.5rem;
  }

  .om-header h2 {
    font-size: 2.6rem;
    letter-spacing: -0.5px;
  }

  .om-header p {
    font-size: 1.1rem;
    line-height: 1.6;
  }

  .profile-section {
    padding: 1.5rem;
    margin-bottom: 4rem;
  }

  .feature-item {
    padding: 1.5rem;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .feature-icon {
    width: 48px;
    height: 48px;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .feature-text h3 {
    font-size: 1.2rem;
  }

  .feature-text p {
    font-size: 0.95rem;
  }

  .contact-info p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px) {
  .om {
    padding: 4rem 0;
  }

  .om-container {
    padding: 0 1rem;
  }

  .om-header h2 {
    font-size: 2.2rem;
  }

  .om-subtitle {
    font-size: 0.75rem;
    letter-spacing: 3px;
    padding: 0.6rem 1.5rem;
    margin-bottom: 1.5rem;
  }

  .profile-section {
    padding: 1rem;
    margin-bottom: 3rem;
  }

  .profile-content h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .profile-image {
    max-width: 250px;
  }

  .contact-info {
    margin-top: 1rem;
  }

  .contact-info p {
    font-size: 0.95rem;
    margin-bottom: 0.8rem;
  }

  .business-info {
    font-size: 0.9rem !important;
  }
}

/* Tilføj support for landscape mode */
@media (max-height: 500px) and (orientation: landscape) {
  .om {
    padding: 5rem 0;
  }

  .profile-section {
    grid-template-columns: 1fr 1.5fr;
    gap: 2rem;
  }

  .profile-image {
    max-width: 200px;
  }
}

.profile-section {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 4rem;
  align-items: center;
  margin-bottom: 6rem;
  background: white;
  padding: 3rem;
  border-radius: 2rem;
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.05);
}

.profile-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.profile-image {
  width: 100%;
  aspect-ratio: 3/4;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  object-fit: cover;
  border: 4px solid rgba(255, 255, 255, 0.9);
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.2),
    0 0 0 15px rgba(255, 255, 255, 0.1);
  animation: morphing 30s ease-in-out infinite;
  transition: all 0.5s ease;
}

.profile-content h2 {
  font-size: 2.8rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  color: #2c1810;
  font-weight: 800;
  letter-spacing: -1px;
  text-align: left;
}

.profile-content p {
  font-size: 1.2rem;
  line-height: 1.7;
  color: #5a4a42;
  opacity: 0.9;
}

@keyframes morphing {
  0%, 100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
  }
  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
}

@media (max-width: 992px) {
  .profile-section {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 2rem;
  }

  .profile-content {
    text-align: left;
    max-width: 400px;
    margin: 0 auto;
  }

  .profile-content h2 {
    text-align: left;
  }

  .contact-info {
    text-align: left;
    margin-top: 1.5rem;
  }
}

@media (max-width: 768px) {
  .profile-section {
    padding: 1.5rem;
    margin-bottom: 4rem;
  }

  .profile-container {
    max-width: 220px;
    display: block;
  }
}

@media (max-width: 480px) {
  .profile-section {
    padding: 1rem;
    margin-bottom: 3rem;
  }

  .profile-container {
    max-width: 180px;
    display: block;
  }
}

/* Landscape mode */
@media (max-height: 500px) and (orientation: landscape) {
  .profile-section {
    grid-template-columns: 0.6fr 1.4fr;
    gap: 2rem;
  }

  .profile-container {
    max-width: 150px;
    display: block;
  }
}

.contact-info {
  margin-top: 2rem;
  text-align: left;
}

.contact-info p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #5a4a42;
  margin-bottom: 1.5rem;
}

.contact-info a {
  color: #3d6351;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-info a:hover {
  color: #325343;
}

.business-info {
  font-size: 1rem !important;
  opacity: 0.8;
}

@media (max-width: 992px) {
  .contact-info {
    display: inline-block;
    text-align: left;
  }
}

.profile-cta {
  margin-top: 2.5rem;
}

.profile-cta .cta-button {
  display: inline-flex;
  padding: 0.9rem 2.2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.profile-cta .cta-button.primary {
  background: #3d6351;
  color: white;
  box-shadow: 0 10px 20px rgba(61, 99, 81, 0.15);
}

.profile-cta .cta-button.primary:hover {
  background: #325343;
  transform: translateY(-2px);
  box-shadow: 0 15px 30px rgba(61, 99, 81, 0.2);
}

@media (max-width: 992px) {
  .profile-cta {
    text-align: center;
  }
}
