.admin-inspiration {
  padding: 2rem;
  padding-top: 240px;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  background: white;
  padding: 2rem;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  z-index: 100;
}

.admin-header-left {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.back-button {
  background: none;
  border: none;
  color: #3d6351;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.back-button:hover {
  background: rgba(61, 99, 81, 0.1);
}

.save-button {
  background: #3d6351;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background: #2c4b3c;
}

.save-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.success-message {
  background: #4caf50;
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.edit-section {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.edit-section h2 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: #2c1810;
}

.input-group {
  margin-bottom: 1.5rem;
}

.input-group:last-child {
  margin-bottom: 0;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #2c1810;
}

.text-input {
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: #2c1810;
}

.text-input:focus {
  outline: none;
  border-color: #3d6351;
}

@media (max-width: 768px) {
  .admin-inspiration {
    padding: 1rem;
    padding-top: 220px;
  }

  .admin-header {
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
  }

  .admin-header-left {
    width: 100%;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .edit-section {
    padding: 1.5rem;
  }
}

.tag {
  background: #f8fafc;
  padding: 0.5rem 1rem;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.25rem;
  border: 2px solid rgba(61, 99, 81, 0.1);
}

.tag span {
  cursor: pointer;
  color: #2c1810;
}

.tag span:hover {
  color: #3d6351;
}

.tag-edit {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tag-edit .text-input {
  padding: 0.3rem 0.5rem;
  width: auto;
  min-width: 100px;
}

.tag-edit-buttons {
  display: flex;
  gap: 0.25rem;
}

.save-edit,
.cancel-edit {
  padding: 0.2rem 0.4rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-edit {
  background: #3d6351;
  color: white;
}

.cancel-edit {
  background: #dc3545;
  color: white;
}

.save-edit:hover {
  background: #2c4b3c;
}

.cancel-edit:hover {
  background: #bd2130;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.input-with-button {
  display: flex;
  gap: 1rem;
}

.input-with-button .text-input {
  flex: 1;
}

.input-with-button .add-button {
  white-space: nowrap;
}

.categories-checkboxes {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 0.5rem;
  border: 2px solid #e2e8f0;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease;
}

.checkbox-label:hover {
  background: rgba(61, 99, 81, 0.05);
}

.checkbox-label input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.categories-list {
  color: #3d6351;
  font-weight: 500;
}

.type {
  color: #5a4a42;
}

.resource-meta {
  margin: 0.5rem 0;
  font-size: 0.9rem;
}

.resource-link {
  color: #2c1810;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: color 0.2s ease;
}

.resource-link:hover {
  color: #3d6351;
}

.external-link-icon {
  font-size: 0.8em;
  opacity: 0.7;
}

.resource-edit-form {
  padding: 1.5rem;
  background: #f8fafc;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.edit-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.cancel-button {
  background: #dc3545;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background: #bd2130;
}

.resource-actions {
  display: flex;
  gap: 0.5rem;
}

.edit-button {
  background: #3d6351;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.edit-button:hover {
  background: #2c4b3c;
}

.types-list {
  margin-top: 1rem;
}

.type-item {
  margin-bottom: 0.5rem;
}

.type-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background: #f8fafc;
  border-radius: 0.5rem;
  border: 2px solid rgba(61, 99, 81, 0.1);
}

.type-name {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.type-name:hover {
  color: #3d6351;
}

.icon-selector {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin: 1rem 0;
  padding: 1rem;
  background: white;
  border-radius: 0.5rem;
  border: 2px solid #e2e8f0;
}

.icon-option {
  padding: 0.5rem;
  border: 2px solid transparent;
  border-radius: 0.5rem;
  background: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.icon-option:hover {
  background: rgba(61, 99, 81, 0.1);
}

.icon-option.active {
  border-color: #3d6351;
  background: rgba(61, 99, 81, 0.1);
}

.type-edit {
  padding: 1rem;
  background: #f8fafc;
  border-radius: 0.5rem;
  border: 2px solid #e2e8f0;
}

@media (max-width: 768px) {
  .admin-inspiration {
    padding-top: 280px;
  }
}